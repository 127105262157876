import React from 'react';
import '../../App.css';
import ServicesIcons from '../ServicesIcons';
import { Helmet } from "react-helmet";

export default function Services() {
  return (
    <div>
    <Helmet>
        <title>Aglobygg</title>
        <meta name="Aglobygg homepage "
         content="renovering, målningörebro, målningEskilstuna ,målningvästerås ,målninglindesberg ,
         målningiörebro ,målningivästerås ,målareiörebro ,målareörebro ,målarevästerås ,målareeskilstuna ,
         målarevästmanlands ,västmanlands ,örebro ,västerås ,snickerikök ,snickeriörebro ,snikareiörebro ,puts ,
         fasadmålning ,fasad ,byggahus ,byggareiörebro ,renoveringörebro  ,badrumsrenovering ,målningörebro ,
         örebromålning ,örebromålare ,snickeriörebro ,örebrosnickeri ,renoveringörebro ,badrumsrenovering ,badrumörebro,
         målningvästerås ,renoveringvästerås ,västeråsmålare ,västeråssnickare ,puts ,fasadmålning ,snickerivästerås ,
         västeråsmålning ,byggahus ,bygganytt ,mleriörebro ,målerivästerås ,målareÖrebro ,målarevästerås ,målarearboga,
         Köksrenovering ,Köksrenoveringörebro ,Köksrenoveringvästerås" 
         data-react-helmet="true">
         
        </meta>
      </Helmet>
  <h1 className='services' id="services">VÅRA TJÄNSTER</h1>
  <ServicesIcons/>
  </div>
  );
}

