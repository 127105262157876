

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './ExtraPage.css';
import 'bootstrap/dist/css/bootstrap.css';
// import {Table} from "react-bootstrap";
import '../App.css';
import FadeInSection from "./FadeInSection"

function ExtraPage() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
<FadeInSection>
<div id="" className=" extrapage-container flex ">


<div class="row large ">
            <div class="col-8">
    <p className="information">
         <h3>Vad drömmer du om?</h3>
                 
         Vad vill du att din bostad, 
         eller din arbetsplats, ska förmedla? Vi är experter på att både förverkliga
         tydliga visioner, men likväl kan vi hantverket att hjälpa er hitta det ni vill ha. 
         Du som kund är alltid välkommen att rådfråga oss om till exempel vilket material 
         som passar bäst till köket, till hur du kan få till den lilla extra känslan i hemmet.
 
         Om du är i behov av ett etablerat byggföretag, 
         är vi ett givet val. Med snabb service och enormt fokus på att alltid överträffa dina
         förväntningar på oss är vi i ständig utveckling.
  
           </p>
           </div>
          <div class="col-4">
          <img className="image-extra " src="/images/extra.jpg" path="/about" alt=""/>
           </div>
  </div>
      


     
      <div className="row small">
    <div className="col-10">
    <p className="information">
         <h3>Vad drömmer du om?</h3>
                 
         Vad vill du att din bostad, 
         eller din arbetsplats, ska förmedla? Vi är experter på att både förverkliga
         tydliga visioner, men likväl kan vi hantverket att hjälpa er hitta det ni vill ha. 
         Du som kund är alltid välkommen att rådfråga oss om till exempel vilket material 
         som passar bäst till köket, till hur du kan få till den lilla extra känslan i hemmet.
 
         Om du är i behov av ett etablerat byggföretag, 
         är vi ett givet val. Med snabb service och enormt fokus på att alltid överträffa dina
         förväntningar på oss är vi i ständig utveckling.
  
           </p>
    </div>
    <div className="row ">
    <div class="col-12 ">
    <img className="image-extra " src="/images/extra.jpg" path="/about" alt=""/>
    </div>
    </div>
  </div>
  </div>
     
      </FadeInSection>
    );
  }
 
   
export default ExtraPage;





 