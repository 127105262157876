import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import AboutUs from './components/pages/AboutUs';
import ErrorPage from "./components/pages/404";




function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Switch>
   
        <Route path='/' exact component={Home} />
        <Route path='/services' component={Services} />
        <Route path='/aboutus' component={AboutUs} />
        <Route component={ErrorPage} />
        
      </Switch>
    </Router>
    </>
  );
}

export default App;