import React, { Component } from "react";
import Slider from "react-slick";
import "./HeroSection.css";
// import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from './Button';
// import { Link } from 'react-router-dom';

export default class HeroSection extends Component {
  render() {
    const settings = {
      dots: true, 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 1000,
      cssEase: "linear"
      
    };
    return (
      <div className="hero-container" id="home">
      
           
            
          
        <h2 className="header">GÖR DITT VAL IDAG 
       <br/> 
       
      </h2>
      <p className="header1 ">DITT HEM, DIN STIL</p> 
      <div className='hero-btns'>
       
      <Button/>
  
      </div>
        <Slider {...settings}>
          
          <div>
            <img className="img" src="/images/8.jpg" path="/about"alt=""/> 
            
          </div>
          <div>
        <img className="img" src="/images/22.jpg" path="/about" alt=""/> 
       
          </div>
          <div>
        <img className="img" src="/images/33.jpg" path="/about" alt=""/>
        
          </div>
          <div>
        <img className="img" src="/images/44.jpg" path="/about" alt=""/>
          </div>
          <div>
        <img className="img" src="/images/55.jpg" path="/about" alt=""/>
          </div>
          <div>
        <img className="img" src="/images/66.jpg" path="/about" alt=""/>
          </div>
        
        </Slider>
      </div>
    );
  }
}


