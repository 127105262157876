import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './ImageText.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Table} from "react-bootstrap";
import FadeInSection from './FadeInSection';

function ImageText() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
      <FadeInSection> 
<div id="about-id" className=" boxes_IT cards flex"> 
<div className="name">
       <h1 className="title-about">OM OSS</h1>
      <Table className="table" >
      
    <tbody>
      <tr className="tr">
        
        <td className="td1"> 
           
            <div className="img_small img-all"><img className="img_first" src="/images/222.jpg" path="/about" alt=""/></div>
        <p className="font_p">
                
       <span className="span-title">Välkommen till</span> <br/> en trygg och effektiv famjilefirma med personlig
kontakt i Örebro. AGLO BYGG &amp; EBTREPRENAD AB är din
trygga entreprenad partner. En viktig del för oss är också
arbete med våra leverantörer vad gäller dagliga leveranser till
våra många arbetsplatser med syftet att få en ännu effektivare
organisation. 
 AGLO BYGG &amp; EBTREPRENAD AB är ett team av erfarna och
    engagerade målare, snickare och plattsätare i Örebro som utför
    allt inom renovering. 
  
              </p>
        </td> 
        <td className="td2">
        <img className="img_first img-all" src="/images/222.jpg" path="/about" alt=""/>
        </td>
      </tr> 
      <tr className="tr">
        
        <td className="td3">
        <img className="img_second img-all" src="/images/44.jpg" path="/about" alt=""/>
        </td>
        <td className="td4">
           {/* <h3> vvv</h3> */}
           <div className="img_small img-all"><img className="img_first" src="/images/44.jpg" path="/about" alt=""/></div>
           <p className="font_p">
                
           <span className="span-title">Vi erbjuder</span> <br/>   renoveringsarbete i Örebro,Lindesberg, Frövi,
Fellingsbro, Arboga, Kungsör, Köping, Eskilstuna.

AGLO BYGG &amp; ENTREPRENAD AB är ett byggföretag som
brinner för vårt arbete. Våra storsta mål är att våra kunder ska
vara extra nöjd.
Kontakta oss. Du är välkommen att kontakta oss på AGLO BYGG &amp;
ENTREPRENAD AB om du har ett projekt du vill ha en kostnadsfri  offert
på eller om du har frågor om något.
  
              </p>
       
        </td>
      </tr>
      <tr className="tr">
        
        <td className="td5"> 
           
            <div className="img_small img-all"><img className="img_first" src="/images/3.jpg" path="/about" alt=""/></div>
        <p className="font_p">
        <span className="span-title"> Vi är </span> <br/> ett byggföretag som jobbar med privatpersoner,
byggföretag, bostadsrättsföreningar och fastighetsägare. Vi har
en egen verkstad på Arboga industriområde, där vi har
möjlighet att utföra fönstermålning, dörrar och köksmöbler i
lokalen. Vi har växt fram av egen kraft, näring har vi hämtat från
våra engagerade medarbetare.
Vi utgår från Arboga där vi har kontor och verkstad, men har
hela Örebro som arbetsområde och utför också uppdrag på
andra orter enligt överenskommelse.
  
              </p>
        </td>
        <td className="td6">
        <img className="img_first img-all" src="/images/3.jpg" path="/about" alt=""/>
        </td>
      </tr> 
       
    </tbody>
  </Table>
      </div>
      </div>
      </FadeInSection>   
    );
  }
 
  
export default ImageText;
