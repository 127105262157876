import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Navbar.css";
import Dropdown from "./Dropdown";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  var idElement = document.getElementById("contact");

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const changeBackground = () => {
    if (window.scrollY >= 600) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="navbar-container">
        <nav className={navbar ? "navbar active" : "navbar"}>
          <div className="navbar-container">
            <Link
              className="navbar-logo"
              onClick={() => {
                if (idElement != null) {
                  const anchor = document.querySelector("#home");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "auto",
                  });
                } else {
                  <Link to="/" onClick={closeMobileMenu} />;
                }
              }}
            ></Link>
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img
                className="logo"
                src="/images/logo2.jpg"
                path="/about"
                alt=""
              />
              {/* <img className="logo" src="/images/uc.png" path="/about" alt="" /> */}
            </Link>
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img
                className="logo2"
                src="/images/uc.png"
                path="/about"
                alt=""
              />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fas fa-times" : "fas fa-bars"} />
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  Hem
                </Link>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Link
                  to="/#services"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Våra tjänster
                </Link>
                {dropdown && <Dropdown />}
              </li>
              <li className="nav-item">
                <Link
                  to="/#aboutus"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Om oss
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/#gallery"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Galleri
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/#contactus"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Kontakta oss
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
