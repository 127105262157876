import React from 'react';
import '../App.css';
import './MyGallery.css'
import Card from 'react-bootstrap/Card';
import FadeInSection from "./FadeInSection";
import 'bootstrap/dist/css/bootstrap.css';


export default function MyGallery() {
  return (
        <FadeInSection>
    <div className="gallery" id="gallery">
       <div className="row  m-5 "> 
       <h1 className="title-gallery">GALLERI</h1>
       
            <div className="col-12 card_p  col-md-6 col-lg-3 justify-content-center ">
            <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex " src={"/images/g11.jpg"} alt="image1" />
              </Card>
               </div>
              
               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g12.jpg"} alt="image1" />
              </Card>
               </div>

               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g13.jpg"} alt="image1" />
              </Card>
            </div>
         
            <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
            <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g14.jpg"} alt="image1" />
              </Card>
               </div>
              
               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g21.jpg"} alt="image1" />
            </Card>
               </div>


               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g22.jpg"} alt="image1" />
              </Card>
            </div>
            

            <div className="col-12 card_p  col-md-6 col-lg-3 justify-content-center ">
            <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex " src={"/images/g23.jpg"} alt="image1" />
              </Card>
               </div>
              
               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g24.jpg"} alt="image1" />
              </Card>
               </div>

               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g31.jpg"} alt="image1" />
              </Card>
            </div>
         
            <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
            <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g32.jpg"} alt="image1" />
              </Card>
               </div>
              
               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g33.jpg"} alt="image1" />
            </Card>
               </div>


               <div className="col-12 card_p col-md-6 col-lg-3 justify-content-center">
               <Card className="card_inside">
              <Card.Img variant="top" className="img_portfolio  d-flex" src={"/images/g34.jpg"} alt="image1" />
              </Card>
            </div>
            
            </div>
        </div> 
        </FadeInSection>
        
  
    );
  }


