import React from "react";
import { Card } from "react-bootstrap";
import './ServicesIcons.css';
import 'bootstrap/dist/css/bootstrap.css';
import FadeInSection from "./FadeInSection";



const ServicesIcons = () => {
  return ( 
    <FadeInSection>
    <div class="container icon-box" >
    <div class="row align-items-center ">
    <div className="col-4  col-md-2 col-lg-2  d-flex justify-content-between "> 
<Card className="first col "  > 
  <i class="fas fa-solid fa-paint-roller fa-2x"></i>
  <Card.Body >
    <p className="title_services">MÅLNING</p>
  </Card.Body>
</Card>
     </div>

     <div className="col-4  col-md-2 col-lg-2 d-flex justify-content-between"> 
<Card className="first col"  >
<i class="fas fa-solid fa-hammer fa-2x"></i>
  <Card.Body>
    <p className="title_services">ALTAN</p>
  </Card.Body>
</Card>
     </div>

     <div className="col-4  col-md-2 col-lg-2 d-flex justify-content-between"> 
<Card className="first col"  >
<i class="fas fa-solid fa-brush fa-2x"></i>
  <Card.Body>
    <p className="title_services">TAKARBETE</p>
  </Card.Body>
</Card>
     </div>

     <div className="col-4  col-md-2 col-lg-2 d-flex justify-content-between"> 
<Card className="first col"  >
<i class="fas fa-solid fa-cubes fa-2x"></i>
  <Card.Body>
    <p className="title_services">FLYTTA</p>
  </Card.Body>
</Card>
     </div>

     <div className="col-4  col-md-2 col-lg-2 d-flex justify-content-between"> 
<Card className="first col"  >
<i class="fas fa-solid fa-screwdriver fa-2x"></i>
  <Card.Body>
    <p className="title_services">SNICKERI</p>
  </Card.Body>
</Card>
     </div> 

     <div className="col-4  col-md-2 col-lg-2 d-flex justify-content-between"> 
<Card className="first col"  >
<i class="fas fa-solid fa-building fa-2x"></i>
  <Card.Body>
    <p className="title_services"> NYBYGGNATION</p>
  </Card.Body>
</Card>
     </div>
 
      
       </div>
       </div>
      </FadeInSection>  
  );
};

export default ServicesIcons;
 