/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import '../../App.css';
import MyGallery from '../MyGallery';
import '../MyGallery.css';
import { Helmet } from "react-helmet";

export default function AboutUs() {
  return (
  <div className='galery' id="galery">
  <Helmet>
        <title>Aglobygg</title>
            <link rel="canonical" href="https://www.aglobygg.se/" />
            <meta keywords="renovering, målningörebro, målningEskilstuna ,målningvästerås ,målninglindesberg ,
         målningiörebro ,målningivästerås ,målareiörebro ,målareörebro ,målarevästerås ,målareeskilstuna ,
         målarevästmanlands ,västmanlands ,örebro ,västerås ,snickerikök ,snickeriörebro ,snikareiörebro ,puts ,
         fasadmålning ,fasad ,byggahus ,byggareiörebro ,renoveringörebro  ,badrumsrenovering ,målningörebro ,
         örebromålning ,örebromålare ,snickeriörebro ,örebrosnickeri ,renoveringörebro ,badrumsrenovering ,badrumörebro,
         målningvästerås ,renoveringvästerås ,västeråsmålare ,västeråssnickare ,puts ,fasadmålning ,snickerivästerås ,
         västeråsmålning ,byggahus ,bygganytt ,mleriörebro ,målerivästerås ,målareÖrebro ,målarevästerås ,målarearboga,
         Köksrenovering ,Köksrenoveringörebro ,Köksrenoveringvästerås" />
            <meta description="Vi på AGLOBYGG har breda kunskaper inom renovering och fixar ditt byggprojekt med hög kvalitet.Välkomna till oss!"  />
            <meta name="description " content="aglobygg.se" data-react-helmet="true"/>
      </Helmet>

  
  <MyGallery/>
  </div> 
  )
}